import React from 'react';
import { Row } from 'reactstrap';
import { colors } from 'config/colors';
import Navbar from 'components/navbar';
import { Portal } from 'helpers/Portal';
import LanguageModal from 'components/common/modals/languageModal';
import { useSelector } from 'react-redux';
import FaqsModal from 'components/common/modals/faqsModal';
import Loading from 'components/common/loading/Loading';
import TermsModal from 'components/common/modals/termsModal';
import NotificationsModal from 'components/common/modals/notificationsModal';
import { ReactComponent as Power } from 'assets/images/logos/PoweredByLokk.svg';
import Socket from 'helpers/socket';

const VerticalLayout = ({ children }) => {

  const { faqsModalOpen, languageModalOpen, notificationsModalOpen, loading, termsModalOpen } = useSelector(state => state?.appConfig);

  const getModalOpen = () => {
    if (notificationsModalOpen) {
      return <NotificationsModal />;
    }
    else if (languageModalOpen) {
      return <LanguageModal />;
    }
    else if (faqsModalOpen) {
      return <FaqsModal />;
    }
    else if (termsModalOpen) {
      return <TermsModal />
    }
    else {
      return null;
    }
  }

  return (
    <Row className='m-0 vh-100 vw-100 p-5 position-relative' style={{ background: `${colors?.white}` }}>
      <Socket token={process.env.REACT_APP_API_TOKEN} showIconStatus />
      {
        loading &&
        <Loading />
      }

      <Power style={{ width: "250px", height: "auto", bottom: 40, left: "calc(50% - 125px)", fill: colors.font }} className="m-0 m p-0 position-absolute" />
      <div className='p-5 m-0 h-100 w-100'>
        <div className="m-0 p-0" style={{ height: "15%" }}>
          <Navbar />
        </div>
        <div id="content-container" className="m-0 p-0" style={{ height: "85%" }}>
          <Portal isOpen={notificationsModalOpen || languageModalOpen || faqsModalOpen || termsModalOpen}>
            {
              getModalOpen()
            }
          </Portal>
          {children}
        </div>
      </div>
    </Row>
  );
}

export default VerticalLayout;
